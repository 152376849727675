<template>
  <b-overlay :show="isLoading">
      <b-row>
        <b-col>
          <h1>
            Whereby
          </h1>
          <b-form-checkbox
            v-model="whereby.active"
            name="active"
            class="content-switch"
            switch
            ><b>Active</b></b-form-checkbox
          >
        </b-col>
        <b-col cols="3" align-self="center">
          <b-alert align-v="center" variant="warning" :show="saveMessage !== ''">{{ this.saveMessage }}</b-alert>
        </b-col>
        <b-col cols="auto">
          <!-- Button -->
          <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
            Save
          </b-button>
        </b-col>
      </b-row>
      <b-form-group label="Room url">
        <b-form-input disabled v-model="whereby.url"></b-form-input>
      </b-form-group>
      <b-form-group label="Roomkey for host">
      <b-form-textarea disabled v-model="roomKey"></b-form-textarea>
      </b-form-group>
      <b-alert :show="roomUrl !== ''" variant="success">New room created at url <a :href="this.roomUrl">{{ this.roomUrl }}</a>, with host room url <a :href="this.hostRoomUrl">{{ this.hostRoomUrl }}</a>! Save changes to use this room.</b-alert>
      <b-button @click="toggleModal()">Create new room</b-button>
      <WherebyCreateRoom 
        v-model="showModal"
        :whereby="whereby"
        :roomUrl="roomUrl"
        :isLoading="isLoading"
        v-on:childToParent="fillRoomUrl"  
      />
    </b-overlay>
</template>
<script>
import WherebyCreateRoom from "@/components/admin/whereby/WherebyCreateRoom.vue"

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      hostRoomUrl: "",
      saveDisabled: true,
      saveMessage: "",
      response: null
    }
  },
  components: {
    WherebyCreateRoom
  },
  async created() {
    this.roomUrl = ""
    await this.$store.dispatch("Whereby/fetchAll");
    this.saveDisabled = true;
    this.saveMessage = "";

  },
  watch: {
    whereby: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    roomKey() {
      return this.whereby.hostRoomUrl.substr(this.whereby.hostRoomUrl.indexOf('=') + 1, this.whereby.hostRoomUrl.length)
    },
    whereby() {
      return this.$store.state.Whereby.all
    }
  },
  methods: {
    async doSave() {
      this.isLoading = true
      await this.$store.dispatch("Whereby/update", this.whereby);
      await this.$store.dispatch("Whereby/fetchAll");
      this.$root.$bvToast.toast("Whereby settings saved!", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    fillRoomUrl(value) {
      this.response = value
      this.whereby.url = value.roomUrl;
      this.whereby.hostRoomUrl = value.hostRoomUrl
      this.whereby.roomKey = this.roomKey
      this.roomUrl = value.roomUrl
      this.hostRoomUrl = value.hostRoomUrl
    }
  },
};
</script>
