<template>
  <b-modal
    v-model="value"
    title="Create new room"
    ref="room-modal"
    id="room-modal"
  >
    <b-form-group label="Host room url">
      <b-form-input v-model="whereby.hostRoomUrl[language]"></b-form-input>
    </b-form-group>
    <b-form-group label="Start date">
      <b-form-input v-model="whereby.startDate"></b-form-input>
    </b-form-group>
    <b-form-group label="End date">
      <b-form-input v-model="whereby.endDate"></b-form-input>
    </b-form-group>
    <b-form-group label="Room size">
      <b-form-select v-model="whereby.roomMode[language]" :options="sizes"></b-form-select>
    </b-form-group>
    <template #modal-footer>
      <b-button type="submit" @click="createRoom()">Create room</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: ["value", "whereby", "isLoading", "language"],
  data() {
    return { 
      sizes: ['normal', 'group'],
      response: ''
    }
  },
  created() {
    console.log(this.whereby)
    this.whereby.hostRoomUrl[this.language] = "https://medialife.whereby.com/8585fcfc-d1a4-404c-98f2-c6e58c6742fe"
    this.whereby.startDate = "2021-07-14T12:25:00.000Z"
    this.whereby.endDate = "2021-12-24T12:24:00.000Z"
    this.whereby.roomMode[this.language] = "group"
    console.log(this.whereby)

  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    async createRoom() {
      this.isLoading = true;
      const body = {
        roomMode: this.whereby.roomMode[this.language],
        startDate: this.whereby.startDate,
        endDate: this.whereby.endDate,
        fields: 'hostRoomUrl',
      };
      this.value = false;
      let response = await this.$store.dispatch("Whereby/createRoom", body);
      this.$emit("childToParent", response);
      this.isLoading = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    
  },
};
</script>
